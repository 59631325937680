<template>
  <office-layout>
    <template v-slot:breadcrumb>
      <a-breadcrumb>
        <a-breadcrumb-item>
          <HomeOutlined />
          <router-link to="/">
            <span>Home</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="{ name: 'redeem' }">
            <span>Redeem</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="{ name: 'redeem.addRedeem' }">
            <span>Add Redeem</span>
          </router-link>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <a-row style="padding-bottom: 30px">
      <a-col :md="{ span: 18, offset: 3 }" :sm="{ span: 24 }">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-item label="Arrival Port" name="arrivalPort">
            <a-input
              v-model:value="formState.arrivalPort"
              placeholder="Customer arrival port"
              size="large"
            />
          </a-form-item>

          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="Booking check-in date" name="bookingCheckIn">
                <a-date-picker
                  v-model:value="formState.bookingCheckIn"
                  type="date"
                  placeholder="Booking check-in date"
                  size="large"
                  style="width: 100%"
                >
                </a-date-picker>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item
                label="Booking check-out date"
                name="bookingCheckOut"
              >
                <a-date-picker
                  v-model:value="formState.bookingCheckOut"
                  type="date"
                  placeholder="Booking check-out date"
                  size="large"
                  style="width: 100%"
                >
                </a-date-picker>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="Room" name="rooms">
                <a-input-number
                  v-model:value="formState.rooms"
                  placeholder="Total room(s)"
                  size="large"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Total Price" name="total">
                <a-input-number
                  v-model:value="formState.total"
                  placeholder="Total price"
                  size="large"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <div v-for="(data, idx) in formState.guests" :key="idx">
            <a-row :gutter="24">
              <a-col :span="24">
                <h3>
                  Person {{ idx + 1 }}
                  <a-button
                    class="deletePerson-button"
                    @click="deletePerson(data)"
                    v-if="formState.guests.length > 1"
                    :disabled="formState.guests.length === 1"
                  >
                    <template #icon>
                      <UserDeleteOutlined />
                    </template>
                  </a-button>
                </h3>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  label="First name"
                  :name="['guests', idx, 'firstname']"
                  :rules="{
                    required: true,
                    message: 'First name is required',
                    trigger: 'change',
                  }"
                >
                  <a-input
                    v-model:value="formState.guests[idx].firstname"
                    placeholder="Customer first name"
                    size="large"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  label="Last name"
                  :name="['guests', idx, 'lastname']"
                  :rules="{
                    required: true,
                    message: 'Last name is required',
                    trigger: 'change',
                  }"
                >
                  <a-input
                    v-model:value="formState.guests[idx].lastname"
                    placeholder="Customer last name"
                    size="large"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  label="Passport Country"
                  :name="['guests', idx, 'passportCountry']"
                  :rules="{
                    required: true,
                    message: 'Passport Country is required',
                    trigger: 'change',
                  }"
                >
                  <a-select
                    v-model:value="formState.guests[idx].passportCountry"
                    size="large"
                    placeholder="Please select customer passport country"
                  >
                    <a-select-option value="" disabled>
                      Please select customer passport country
                    </a-select-option>

                    <a-select-option
                      v-for="data in country"
                      :key="data.code"
                      :value="data.code"
                    >
                      {{ data.code }} - {{ data.country_en }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <hr />
              </a-col>
            </a-row>
          </div>
          <a-row :gutter="8" justify="center" style="padding-bottom: 20px">
            <a-button @click="addPersonInput">
              <template #icon>
                <UserAddOutlined />
              </template>
              Add Person
            </a-button>
          </a-row>

          <a-row :gutter="8">
            <a-col :span="8">
              <p>Hotel Booking Confirmation (JPEG, PNG)</p>
              <ImgUpload
                title="Click to upload"
                bucketName="hotelcertificate"
                @callback="setImageBookingConfirmation"
              />
            </a-col>
            <a-col :span="8" v-if="false">
              <!-- <p>
                <span style="color: #ff4d4f">*</span> Customer Boarding Pass or Air Ticket (1
                Person) (JPEG, PNG)
              </p>
              <ImgUpload
                title="Click to upload"
                bucketName="hotelcertificate"
                @callback="setImageBordingPass"
              /> -->
            </a-col>
            <a-col :span="8" v-if="false">
              <!-- <p>
                <span style="color: #ff4d4f">*</span> Customer Folio (JPEG, PNG)
              </p>
              <ImgUpload
                title="Click to upload"
                bucketName="hotelcertificate"
                @callback="setImageFolio"
              /> -->
            </a-col>
          </a-row>

          <a-row justify="center">
            <a-button
              type="primary"
              size="large"
              @click="onSubmit"
              :disabled="disableSubmitButton"
              class="main-button"
            >
              <template #icon>
                <RightSquareOutlined />
              </template>
              Submit
            </a-button>
          </a-row>
        </a-form>
      </a-col>
    </a-row>
  </office-layout>
</template>

<script>
import { defineComponent, reactive, toRaw, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import ImgUpload from "../../components/ImgUpload";
import {
  HomeOutlined,
  RightSquareOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons-vue";
import countryCode from "../../constant/country_codes.json";
import { isEmpty, orderBy } from "lodash";
import moment from "moment";

export default defineComponent({
  components: {
    HomeOutlined,
    RightSquareOutlined,
    UserAddOutlined,
    UserDeleteOutlined,
    ImgUpload,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const formRef = ref();
    const disableSubmitButton = ref(false);
    const country = orderBy(countryCode, ["code"], ["asc"]);

    const formState = reactive({
      packageId: null,
      arrivalPort: null,
      rooms: null,
      total: null,
      bookingCheckIn: null,
      bookingCheckOut: null,
      guests: [{ firstname: null, lastname: null, passportCountry: null }],
      // bookingConfirmation: {
      //   bucketName: false,
      //   fileName: false,
      // },
      // bordingPass: {
      //   bucketName: false,
      //   fileName: false,
      // },
      // folio: {
      //   bucketName: false,
      //   fileName: false,
      // },

      // to-do: เสร็จแล้วลบด้วย
      // packageId: null,
      // arrivalPort: "สนามบิน",
      // rooms: "2",
      // total: "3000",
      // bookingCheckIn: null,
      // bookingCheckOut: null,
      // guests: [
      //   { firstname: "fname1", lastname: "lname1", passportCountry: "ALB" },
      //   { firstname: "fname2", lastname: "lname2", passportCountry: "CHN" },
      // ],

      // bookingConfirmation: {
      //   bucketName: "hotelcertificate",
      //   fileName: "97a61ee5-bfd2-4dcf-bb0b-8f886b592f7e.jpg",
      // },
      // bordingPass: {
      //   bucketName: "hotelcertificate",
      //   fileName: "32ce7df9-f973-4fa4-9358-e427ddd88c40.png",
      // },
      // folio: {
      //   bucketName: "hotelcertificate",
      //   fileName: "68fb12f5-82be-4350-8a8f-9a5e2fa95c84.jpg",
      // },
    });

    const addPersonInput = () => {
      formState.guests.push({
        firstname: null,
        lastname: null,
        passportCountry: null,
      });
    };

    const deletePerson = (item) => {
      let index = formState.guests.indexOf(item);

      if (index !== -1) {
        formState.guests.splice(index, 1);
      }
    };

    onMounted(async () => {
      // ดึง package มาใช้มาอ้างอิง
      const packages = await store.dispatch("packages/fetchPackage", {});
      if (!isEmpty(packages.data)) {
        formState.packageId = packages.data[0].id;
      } else {
        formState.packageId = null;
      }
    });

    /* -------------
    - Upload File -
    ------------- */
    const setImageBookingConfirmation = (res) => {
      formState.bookingConfirmation = {
        bucketName: res.bucketName,
        fileName: res.fileName,
      };
    };
    // const setImageBordingPass = (res) => {
    //   formState.bordingPass.bucketName = res.bucketName;
    //   formState.bordingPass.fileName = res.fileName;
    // };
    // const setImageFolio = (res) => {
    //   formState.folio.bucketName = res.bucketName;
    //   formState.folio.fileName = res.fileName;
    // };
    /* ------------- */

    const rules = {
      arrivalPort: [{ required: true, message: "Arrival port is required" }],
      rooms: [{ required: true, message: "Total room(s) is required" }],
      total: [{ required: true, message: "Total price is required" }],
      bookingCheckIn: [
        {
          required: true,
          message: "Please pick a date of booking check-in",
        },
      ],
      bookingCheckOut: [
        {
          required: true,
          message: "Please pick a date of booking check-out",
        },
      ],
    };

    const onSubmit = () => {
      // check check-in date and check-out date
      if (
        formState.bookingCheckOut != null &&
        formState.bookingCheckIn != null
      ) {
        formState.bookingCheckOut = moment(
          formState.bookingCheckOut.format("YYYY-MM-DD")
        );
        formState.bookingCheckIn = moment(
          formState.bookingCheckIn.format("YYYY-MM-DD")
        );
        if (
          formState.bookingCheckOut.diff(formState.bookingCheckIn, "days") < 3
        ) {
          message.error("ลูกค้าจะต้องเข้าพักอย่างน้อย 3 คืน");
          return;
        }
      }

      // check validate img
      // if (
      //   formState.bookingConfirmation.bucketName == false ||
      //   formState.bookingConfirmation.fileName == false
      // ) {
      //   message.error("Please upload Booking Confirmation (JPEG, PNG).");
      //   return;
      // }
      // if (
      //   formState.bordingPass.bucketName == false ||
      //   formState.bordingPass.fileName == false
      // ) {
      //   message.error("Please upload Boarding Pass or Air Ticket (JPEG, PNG).");
      //   return;
      // }
      // if (
      //   formState.folio.bucketName == false ||
      //   formState.folio.fileName == false
      // ) {
      //   message.error("Please upload Folio (JPEG, PNG).");
      //   return;
      // }

      formRef.value
        .validate()
        .then(async () => {
          try {
            console.log("submit!", {
              ...toRaw(formState),
              bookingCheckIn: formState.bookingCheckIn.format("YYYY-MM-DD"),
            });

            disableSubmitButton.value = true;
            await store.dispatch(`redeem/createRedeem`, formState);
            message.success("Add Redeem is success.");
            setTimeout(() => {
              router.push({ name: "redeem" });
            }, 200);
          } catch (e) {
            console.log(e);
            disableSubmitButton.value = false;
            message.error(
              (e.response && e.response.data && e.response.data.message) ||
                "This is an error message, cannot save redeem information, please try again."
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    return {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      formState,
      formRef,
      onSubmit,
      rules,
      setImageBookingConfirmation,
      // setImageBordingPass,
      // setImageFolio,
      disableSubmitButton,
      addPersonInput,
      deletePerson,
      country,
    };
  },
});
</script>

<style scoped>
.deletePerson-button {
  background: #eb2f96;
  border-color: #eb2f96;
  color: #ffffff;
  font-weight: bold;
}
</style>
